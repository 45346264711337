import React, { FC } from 'react';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import Hero from 'components/hero';
import WhitePaperDownloadForm from 'containers/whitePaperDownloadForm';

interface Props {
  location: string;
}

const ScenariosOf2030WhitepaperSignup: FC<Props> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Sign up Scenarios of 2030 Whitepaper" />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero title={'Scenarios of 2030+'} hero="scenarios-of-2030" />
        <WhitePaperDownloadForm
          title={'Scenarios of 2030+ Whitepaper Download'}
          formGroupId={'group[68204][32]'}
          journeyId={2288}
          stepId={16648}
          listId={'ba1454213b'}
        />
      </main>
      <Footer />
    </Layout>
  );
};

export default ScenariosOf2030WhitepaperSignup;
